import getSGMClient from 'src/lib/services/sgm/axios.config';
import { SGMPromptType } from 'src/lib/types/sgmPrompt';
import { getMessageFromError } from 'src/lib/utils/error';

export async function updateMakerPrompt(type: SGMPromptType, prompt: string) {
  try {
    const api = await getSGMClient();
    const res = await api.put(`/api/admin/prompt/${type}`, {
      prompt,
    });
    return {
      data: res.data,
      error: null,
    };
  } catch (e) {
    return {
      data: null,
      error: getMessageFromError(e),
    };
  }
}
