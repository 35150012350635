import { Add, Download } from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  Container,
  InputAdornment,
  Stack,
  Tab,
  Tabs,
  TextField,
} from '@mui/material';
import { MODERATED_OBJECT_STATUS_TABS } from 'src/components/modules/Review/utils/utils';
import Flex from 'src/components/shared/Flex';
import { LabelValue } from 'src/lib/types/labelValue';
import {
  IncludeExcludeDefaultOption,
  IncludeExcludeOptions,
} from 'src/lib/utils/includeExcludeFilterUtils';
import HeaderBreadcrumbs from 'src/template/components/HeaderBreadcrumbs';
import Iconify from 'src/template/components/Iconify';
import GamesTable from '../../components/GamesTable/GamesTable';
import useGames from './useView';

const STATUS_TABS = MODERATED_OBJECT_STATUS_TABS;

export default function Games() {
  const {
    data,
    isLoading,
    params,
    handleOpenCreateModal,
    handleDownloadActiveGamesCSV,
    currentTab,
    onChangeTab,
    debouncedSearch,
    addParam,
    removeParam,
    allSearchParams,
  } = useGames();
  return (
    <Container maxWidth="xl">
      <HeaderBreadcrumbs
        heading="Games"
        links={[{ name: 'Games' }]}
        action={
          <Button
            variant="contained"
            color="secondary"
            onClick={handleOpenCreateModal}
            startIcon={<Add />}
          >
            Add Game
          </Button>
        }
      />

      <Flex alignItems={'end'} justifyContent={'end'} mb="15px">
        <Button
          onClick={handleDownloadActiveGamesCSV}
          variant="contained"
          color="secondary"
          startIcon={<Download />}
        >
          Generate CSV
        </Button>
      </Flex>

      <Card>
        <Flex sx={{ px: 2, bgcolor: 'background.neutral' }}>
          <Tabs
            allowScrollButtonsMobile
            variant="scrollable"
            scrollButtons="auto"
            value={currentTab}
            onChange={onChangeTab}
          >
            {STATUS_TABS.map((tab) => (
              <Tab
                disableRipple
                key={tab.value}
                label={tab.label}
                value={tab.value}
              />
            ))}
          </Tabs>
        </Flex>

        <CardContent>
          <Stack p={2} gap={2}>
            <Flex gap={2} justifyContent={'flex-end'}>
              <Autocomplete
                options={SEARCH_OPTIONS}
                fullWidth
                renderInput={(_params) => (
                  <TextField label="Search By" {..._params} />
                )}
                getOptionLabel={(p) => p.label}
                sx={{ maxWidth: 250 }}
                defaultValue={SEARCH_OPTIONS.find(
                  (o) => o.value === params.searchBy
                )}
                key={params.searchBy}
                onChange={(_, value) => {
                  if (!value) removeParam('searchBy');
                  else addParam('searchBy', value.value);
                }}
              />
              <TextField
                fullWidth
                label="Search"
                defaultValue={allSearchParams.query}
                type="search"
                onChange={(e) => {
                  const { value } = e.target;
                  debouncedSearch(value);
                }}
                key={allSearchParams.query}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Iconify
                        icon="eva:search-fill"
                        sx={{ color: 'text.disabled', width: 20, height: 20 }}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            </Flex>
            <Autocomplete
              sx={{
                width: '200px',
              }}
              disableClearable
              id="bySGM-filter"
              options={IncludeExcludeOptions}
              isOptionEqualToValue={(item, value) =>
                item?.value === value?.value
              }
              value={
                IncludeExcludeOptions.find((o) => o.value === params.bySGM) ??
                IncludeExcludeDefaultOption
              }
              renderInput={(_params) => (
                <TextField {..._params} label="By SGM Filter" />
              )}
              onChange={(_, option) => {
                if (option) addParam('bySGM', option.value);
                else removeParam('bySGM');
              }}
            />
          </Stack>
          <Box px={2}>
            <GamesTable
              data={data?.rows || []}
              totalCount={data?.count || 0}
              isLoading={isLoading}
              params={params}
            />
          </Box>
        </CardContent>
      </Card>
    </Container>
  );
}

const SEARCH_OPTIONS: LabelValue[] = [
  {
    label: 'Game name',
    value: 'name',
  },
  {
    label: 'Game UUID',
    value: 'uuid',
  },
  {
    label: 'Publisher name',
    value: 'gamePublisher.name',
  },
  {
    label: 'Developer name',
    value: 'developerName',
  },
  {
    label: 'Game Mode name',
    value: 'versions.gameMode.name',
  },
  {
    label: 'Category name',
    value: 'categories.name',
  },
  {
    label: 'Tag name',
    value: 'tags.name',
  },
];
