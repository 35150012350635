import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import { capitalCase } from 'change-case';
import { useState } from 'react';
import Flex from 'src/components/shared/Flex';
import { SGMPrompt, SGMPromptType } from 'src/lib/types/sgmPrompt';
import HeaderBreadcrumbs from 'src/template/components/HeaderBreadcrumbs';
import MAKER_PROMPTS_TABS from './promptsTabs';
import useMakerPrompts from './useView';

export default function MakerPrompts() {
  const {
    data,
    isLoading,
    currentTab,
    onChangeTab,
    handleUpdatePrompt,
    isSubmitting,
  } = useMakerPrompts();
  return (
    <Container maxWidth="xl">
      <HeaderBreadcrumbs
        heading="SGM Prompts"
        links={[{ name: 'SGM Prompts' }]}
      />

      <Card>
        <Flex sx={{ px: 2, bgcolor: 'background.neutral' }} mb={2}>
          <Tabs
            allowScrollButtonsMobile
            variant="scrollable"
            scrollButtons="auto"
            value={currentTab}
            onChange={onChangeTab}
          >
            {MAKER_PROMPTS_TABS.map((tab) => (
              <Tab
                disableRipple
                key={tab.value}
                value={tab.value}
                icon={tab.icon}
                label={capitalCase(tab.value)}
              />
            ))}
          </Tabs>
        </Flex>
        <CardContent>
          {isLoading || isSubmitting ? (
            <Flex justifyContent="center" alignItems="center">
              <CircularProgress />
            </Flex>
          ) : (
            <MakerPromptViewer
              key={data?.id ?? currentTab}
              prompt={data!}
              onEdit={handleUpdatePrompt}
            />
          )}
        </CardContent>
      </Card>
    </Container>
  );
}

interface MakerPromptViewerProps {
  prompt: SGMPrompt;
  onEdit: (type: SGMPromptType, updatedPrompt: string) => void;
}

export function MakerPromptViewer({ prompt, onEdit }: MakerPromptViewerProps) {
  const [isEditing, setIsEditing] = useState(false);
  const [newPrompt, setNewPrompt] = useState(prompt.prompt);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = () => {
    onEdit(prompt.type, newPrompt);
    setIsEditing(false);
  };

  const handleCancelClick = () => {
    setNewPrompt(prompt.prompt);
    setIsEditing(false);
  };

  return (
    <>
      {isEditing ? (
        <TextField
          value={newPrompt}
          onChange={(e) => setNewPrompt(e.target.value)}
          fullWidth
          multiline
          minRows={4} // Define quantas linhas mínimas o textarea deve ter
        />
      ) : (
        <Typography
          variant="body1"
          style={{ whiteSpace: 'pre-line' }}
          sx={{
            whiteSpace: 'pre-line',
            padding: '10px',
            borderRadius: '10px',
            border: '1px solid #ddd',
          }}
        >
          {prompt.prompt}
        </Typography>
      )}

      <Stack direction="row" spacing={2} marginTop={2}>
        {isEditing ? (
          <>
            <Button
              variant="outlined"
              color="error"
              onClick={handleCancelClick}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="success"
              onClick={handleSaveClick}
            >
              Save
            </Button>
          </>
        ) : (
          <Button variant="contained" color="info" onClick={handleEditClick}>
            Edit
          </Button>
        )}
      </Stack>
    </>
  );
}
