import { ReactElement } from 'react';
import { SGMPromptType } from 'src/lib/types/sgmPrompt';
import Iconify, { IconifyProps } from 'src/template/components/Iconify';

interface IMakerPromptTabs {
  value: SGMPromptType;
  icon: ReactElement<IconifyProps>;
}

const MAKER_PROMPTS_TABS: IMakerPromptTabs[] = [
  {
    value: SGMPromptType.GAME_CHAT,
    icon: (
      <Iconify
        icon="mdi:chat"
        style={{ fontSize: 20 }}
        width={20}
        height={20}
      />
    ),
  },
  {
    value: SGMPromptType.GAME_DETAILS,
    icon: (
      <Iconify
        icon="mdi:information"
        style={{ fontSize: 20 }}
        width={20}
        height={20}
      />
    ),
  },
  {
    value: SGMPromptType.PHASER,
    icon: (
      <Iconify
        icon="mdi:target"
        style={{ fontSize: 20 }}
        width={20}
        height={20}
      />
    ),
  },
  {
    value: SGMPromptType.PHASER_OPEN,
    icon: (
      <Iconify
        icon="mdi:target"
        style={{ fontSize: 20 }}
        width={20}
        height={20}
      />
    ),
  },
  {
    value: SGMPromptType.PHASER_AGENT,
    icon: (
      <Iconify
        icon="mdi:target"
        style={{ fontSize: 20 }}
        width={20}
        height={20}
      />
    ),
  },
  {
    value: SGMPromptType.GAME_CHAT_OPEN,
    icon: (
      <Iconify
        icon="mdi:chat"
        style={{ fontSize: 20 }}
        width={20}
        height={20}
      />
    ),
  },
  {
    value: SGMPromptType.GAME_CHAT_AGENT,
    icon: (
      <Iconify
        icon="mdi:chat"
        style={{ fontSize: 20 }}
        width={20}
        height={20}
      />
    ),
  },
];

export default MAKER_PROMPTS_TABS;
