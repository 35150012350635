import { useCallback, useEffect, useState } from 'react';
import useNotify from 'src/lib/hooks/useNotify';
import { SGMPromptType } from 'src/lib/types/sgmPrompt';
import useTabs from 'src/template/hooks/useTabs';
import { SGMPromptEmitter } from '../../events/SGMPromptEmitter';
import { fetchMakerPrompt } from '../../services/fetchMakerPrompt';
import { updateMakerPrompt } from '../../services/updateMakerPrompt';

const DEFAULT_TAB = SGMPromptType.GAME_CHAT;

export default function useMakerPrompts() {
  const { currentTab, onChangeTab } = useTabs(DEFAULT_TAB, {
    resetOnChange: true,
  });
  const notify = useNotify();

  const { data, isLoading, mutate } = fetchMakerPrompt(
    currentTab as SGMPromptType
  );

  const refetchData = useCallback(() => {
    mutate?.();
  }, []);

  useEffect(() => {
    SGMPromptEmitter.on('SGMPromptUpdated', refetchData);
    return () => {
      SGMPromptEmitter.off('SGMPromptUpdated', refetchData);
    };
  }, [refetchData]);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleUpdatePrompt = async (type: SGMPromptType, prompt: string) => {
    setIsSubmitting(true);
    try {
      const { data: promptResponse, error } = await updateMakerPrompt(
        type,
        prompt
      );
      if (error) {
        notify(error, { variant: 'error' });
      } else {
        notify('Prompt Updated!', { variant: 'success' });
        SGMPromptEmitter.emit('SGMPromptUpdated', null);
      }
    } catch (error: any) {
      notify(error?.message || error?.description, { variant: 'error' });
    } finally {
      setIsSubmitting(false);
    }
  };

  return {
    data,
    isLoading,
    currentTab,
    onChangeTab,
    handleUpdatePrompt,
    isSubmitting,
  };
}
