import { useCallback, useEffect } from 'react';
import { useParams } from 'react-router';
import { DEFAULT_INITIAL_PAGE_SIZE } from 'src/lib/constants/pagination';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import useModalStore from 'src/lib/stores/useModalStore';
import { AdminListGamesRow } from 'src/lib/types/gameRow';
import useTabs from 'src/template/hooks/useTabs';
import { GameEmitter } from '../../events/GameEmitter';
import { fetchGame } from '../../services/fetchGame';
import UpdateGameView from '../Update';
import { GameTabs } from './gameTabs';

const DEFAULT_TAB = GameTabs.MAIN;

export default function useGameDetails() {
  const { gameUuid } = useParams<'gameUuid'>();
  const openModal = useModalStore((state) => state.openModal);
  const { currentTab, onChangeTab } = useTabs(DEFAULT_TAB, {
    resetOnChange: true,
  });

  const handleUpdateGame = async (game: AdminListGamesRow) => {
    openModal(<UpdateGameView game={game} />);
  };

  const {
    data: game,
    isLoading: gameIsLoading,
    mutate: mutateDetails,
  } = fetchGame(gameUuid!);

  const refetchDetailsData = useCallback(() => {
    mutateDetails?.();
  }, []);

  useEffect(() => {
    GameEmitter.on('GameUpdated', refetchDetailsData);

    return () => {
      GameEmitter.off('GameUpdated', refetchDetailsData);
    };
  }, [refetchDetailsData]);

  const { allSearchParams } = useURLSearchParams({
    sortBy: 'version',
    order: 'asc',
    page: '0',
    size: DEFAULT_INITIAL_PAGE_SIZE.toString(),
    searchBy: 'version',
  });

  const query: any = {};
  if (allSearchParams.searchBy && allSearchParams.query) {
    query[allSearchParams.searchBy] = {
      like: `%${allSearchParams.query}%`,
    };
  }

  return {
    handleUpdateGame,
    game,
    gameIsLoading,
    currentTab,
    onChangeTab,
  };
}

export type FetchGameVersionsQueryParams = {
  page: number;
  size: number;
  searchBy: string | undefined;
  orderBy: string;
  order: string;
  query: string;
};
