import { ContentCopy } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import LinkIcon from '@mui/icons-material/Link';
import UploadIcon from '@mui/icons-material/Upload';
import {
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  Stack,
  SxProps,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import Flex from './Flex';
import Link from './Link';

type Props = {
  type: 'image' | 'video' | 'html-file';
  uploadButtonLabel?: string;
  changeButtonLabel?: string;
  content: string;
  onEdit: (file: any) => void;
  onRemove: () => void;
  sx?: SxProps;
  showEditButton?: boolean;
  isLoading?: boolean;
};

export default function UploadContent({
  type,
  content,
  onEdit,
  isLoading,
  onRemove,
  uploadButtonLabel,
  changeButtonLabel,
  ...otherProps
}: Props) {
  const [isCopied, setIsCopied] = useState(false);
  useEffect(() => {
    if (isCopied) {
      setTimeout(() => {
        setIsCopied(false);
      }, 500);
    }
  }, [isCopied]);

  return (
    <Stack>
      <Flex gap={'8px'} alignItems="center">
        <TextField
          {...otherProps}
          disabled={isLoading}
          placeholder={`Insert ${type} URL`}
          sx={{ width: '100%' }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip title={isCopied ? 'Copied!' : 'Copy URL'}>
                  <IconButton onClick={() => setIsCopied(true)}>
                    <CopyToClipboard text={content}>
                      <ContentCopy color={isCopied ? 'success' : 'primary'} />
                    </CopyToClipboard>
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            ),
          }}
        />

        {!content ? (
          <>
            <Typography variant="h6" sx={{ color: 'text.secondary' }}>
              or
            </Typography>
            <Button
              size="large"
              startIcon={
                isLoading ? <CircularProgress size={20} /> : <UploadIcon />
              }
              className="edit-cover-button"
              component="label"
              sx={{
                minWidth: '170px',
                backgroundColor: 'black',
                color: 'white !important',
                borderRadius: '8px',
                transition: 'all 0.3s',
                height: '56px',
                '&:hover': {
                  backgroundColor: 'black',
                  transform: 'translate(-2px, -2px)',
                },
              }}
            >
              {isLoading
                ? 'Uploading...'
                : content
                ? changeButtonLabel ?? `Change ${type}`
                : uploadButtonLabel ?? `Upload ${type}`}
              <input
                type="file"
                hidden
                accept={
                  type === 'image'
                    ? 'image/*'
                    : type === 'video'
                    ? 'video/*'
                    : '.html'
                }
                multiple={false}
                {...otherProps}
                disabled={isLoading}
                onChange={(e) => {
                  onEdit(e.target?.files?.[0]);
                }}
              />
            </Button>
          </>
        ) : (
          <Flex alignItems="center" gap={'4px'} justifyContent="start">
            <LinkIcon fontSize="small" sx={{ color: '#7a8599' }} />

            <Link
              to={content}
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                maxWidth: '204px',
                fontSize: '14px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              Preview
            </Link>
            <Tooltip title="Remove content">
              <DeleteIcon
                color="error"
                sx={{ cursor: 'pointer' }}
                fontSize="small"
                onClick={onRemove}
              />
            </Tooltip>
          </Flex>
        )}
      </Flex>
    </Stack>
  );
}
