import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { fetchGameCategory } from 'src/components/modules/GameCategories/services/fetch';
import { fetchGameMode } from 'src/components/modules/GameMode/services/fetch';
import { fetchGameTag } from 'src/components/modules/GameTags/services/fetch';
import { getDefaultObjectLinksOptions } from 'src/components/shared/Form/useObjectLinkSearch';
import useModalStore from 'src/lib/stores/useModalStore';
import { GamePublishRights } from 'src/lib/types/game';
import { AdminListGamesRow } from 'src/lib/types/gameRow';
import { ObjectLinkTypes } from 'src/lib/types/objectLink';
import { uploadToStorage } from 'src/lib/utils/upload';
import * as yup from 'yup';
import { fetchGamePublishers } from '../../../services/fetchGamesPublishers';
import { UpdateGameFormValues } from './types';

const validationSchema = yup.object().shape({
  game: yup.object().shape({
    name: yup.string().required('Name is required'),
    publishRightsType: yup
      .string()
      .oneOf(Object.values(GamePublishRights))
      .required('Publish Rights is required'),
  }),

  version: yup.object().shape({
    url: yup.string().url().required('URL is required'),
  }),
});

function useUpdateGameFormView({
  defaultValues,
  game,
}: {
  defaultValues: UpdateGameFormValues;
  game?: AdminListGamesRow;
}) {
  const onClose = useModalStore((state) => state.closeModal);
  const form = useForm<UpdateGameFormValues>({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  const {
    formState: { isSubmitting },
    trigger,
    setValue,
    watch,
  } = form;

  const handleCloseModal = () => {
    onClose();
  };

  // step

  const [currentStep, setCurrentStep] = useState(1); // 1: Game Info, 2: Version Info
  const lastStep = 2;

  const handleNextStep = async () => {
    if (currentStep === 1) {
      const isValid = await trigger('game');
      if (!isValid) return;
    }
    setCurrentStep(currentStep + 1);
  };

  const handlePreviousStep = () => {
    setCurrentStep(currentStep - 1);
  };

  // image

  const imageUrl = watch('version.imageUrl');

  const onUpdateImage = async (file: any) => {
    const url = await uploadToStorage(defaultValues.game.uuid, file);

    setValue('version.imageUrl', url as string);
  };

  // game publishers
  const { data: gamePublishers } = fetchGamePublishers();

  /* Game Categories */
  const [gameCategorySearchText, setGameCategorySearchText] =
    useState<string>('');
  const { data: gameCategoriesData, isLoading: isLoadingGameCategory } =
    fetchGameCategory({
      page: 0,
      size: 5,
      filter: {
        ...(gameCategorySearchText && {
          name: {
            like: `%${gameCategorySearchText}%`,
          },
        }),
      },
      order: {
        createdAt: 'desc',
      },
    });
  const gameCategories = {
    options: gameCategoriesData?.map((item) => ({
      label: item.name,
      uuid: item.uuid,
    })),
    isLoading: isLoadingGameCategory,
    disabled: isSubmitting,
    defaultValue: {
      label:
        game?.categories && game.categories.length > 0
          ? game.categories[0].name
          : '',
      uuid:
        game?.categories && game.categories.length > 0
          ? game.categories[0].uuid
          : '',
    },
    onInputChange: (event: React.ChangeEvent<{}>, newValue: any) => {
      setGameCategorySearchText(newValue);
    },
  };

  /* Game Tags */
  const [tagsSearchText, setTagsSearchText] = useState<string>('');
  useState<string>('');
  const { data: tagsData, isLoading: isLoadingTags } = fetchGameTag({
    page: 0,
    size: 5,
    filter: {
      ...(tagsSearchText && {
        name: {
          like: `%${tagsSearchText}%`,
        },
      }),
    },
    order: {
      createdAt: 'desc',
    },
  });
  const tags = {
    options: tagsData?.map((item) => ({
      label: item.name,
      uuid: item.uuid,
    })),
    isLoading: isLoadingTags,
    disabled: isSubmitting,
    defaultValue: game?.tags?.map((tag) => ({
      label: tag.name,
      uuid: tag.uuid,
    })),
    onInputChange: (event: React.ChangeEvent<{}>, newValue: any) => {
      setTagsSearchText(newValue);
    },
  };

  /* Game Modes */
  const [gameModesSearchText, setGameModesSearchText] = useState<string>('');

  const { data: gameModesData, isLoading: isLoadingGameModes } = fetchGameMode({
    page: 0,
    size: 5,
    filter: {
      ...(gameModesSearchText && {
        name: {
          like: `%${gameModesSearchText}%`,
        },
      }),
    },
    order: {
      createdAt: 'desc',
    },
  });
  const gameModes = {
    options: gameModesData?.map((item) => ({
      label: item.name,
      uuid: item.uuid,
    })),
    isLoading: isLoadingGameModes,
    disabled: isSubmitting,
    defaultValue: game?.modes?.map((mode) => ({
      label: mode.name,
      uuid: mode.uuid,
    })),
    onInputChange: (event: React.ChangeEvent<{}>, newValue: any) => {
      setGameModesSearchText(newValue);
    },
  };

  /* Object Links */
  const defaultValue = getDefaultObjectLinksOptions(game?.objectLinks ?? []);

  /* Object Links */
  const objectLinkProps = {
    form,
    objectLinksToCreateField: 'objectLinks',
    objectLinksTempField: 'objectLinksTempSearch',
    modelsToSearch: [
      ObjectLinkTypes.COLLECTION,
      ObjectLinkTypes.CLUB,
      ObjectLinkTypes.CHANNEL,
    ],
    defaultValue,
  };

  // Upload HTML content for version url
  const [isUpdatingContent, setIsUpdatingContent] = useState<boolean>(false);

  const onUpdateContent = async (file: any) => {
    setIsUpdatingContent(true);

    try {
      const gameUuid = defaultValues.game.uuid;
      const url = await uploadToStorage(`game/${gameUuid}`, file, 'code');

      form.setValue('version.url', url as string);
    } finally {
      setIsUpdatingContent(false);
    }
  };

  return {
    form,
    isSubmitting,
    handleCloseModal,
    currentStep,
    lastStep,
    handleNextStep,
    handlePreviousStep,
    imageUrl,
    onUpdateImage,
    gamePublishers,
    gameCategories,
    tags,
    gameModes,
    objectLinkProps,
    isMobile: watch('game.isMobile'),
    isGameMakerTemplate: watch('game.isGameMakerTemplate'),
    hasAds: watch('version.hasAds'),
    onUpdateContent,
    isUpdatingContent,
  };
}

export default useUpdateGameFormView;
