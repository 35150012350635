import getAPIClient from 'src/lib/services/api/axios.config';
import { ServiceResponse } from 'src/lib/types/ServiceResponse';
import { Game, GameOrientations, GamePublishRights } from 'src/lib/types/game';
import { linksToCreate } from 'src/lib/types/objectLink';
import { getMessageFromError } from 'src/lib/utils/error';

export type UpdateGameValidatedInput = {
  uuid?: string | null;
  name: string;
  orientation?: GameOrientations | null;
  isMobile?: boolean | null;
  description?: string | null;
  hasAgreedTerms?: boolean | null;
  secondaryLink?: string | null;
  gamePublisher?: string | null; // publisher name
  developerName?: string | null;
  gameCategoryUuid?: string | null;
  gameTagsNames?: string[] | null;
  publishRightsType: GamePublishRights;
  objectLinks?: linksToCreate[] | null;

  url: string;
  version?: string | null;
  imageUrl?: string | null;
  gameModesUuids?: string[] | null;
  ageRestriction?: number | null;
  hasAds?: boolean | null;
  additionalComments?: string | null;
};

export async function UpdateGame(
  uuid: string,
  data: UpdateGameValidatedInput
): Promise<ServiceResponse<Game>> {
  try {
    const api = await getAPIClient();
    const res = await api.put(`admin/game/${uuid}`, data);
    return {
      data: res.data,
      error: null,
    };
  } catch (e) {
    return {
      data: null,
      error: getMessageFromError(e),
    };
  }
}
